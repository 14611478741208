import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class WindowRefService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  get nativeWindow(): any {
    if (isPlatformBrowser(this.platformId)) {
      return _window();
    }
  }

  isMobile(): boolean {
    if (window.innerWidth < 767) {
      document.body.classList.add('abc-app-notification-space');
      return true;
    } else {
      return false;
    }
  }
}
