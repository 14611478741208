<div class="abc-popup-custom-design">
  <div class="modal-header">
    <h3>Error</h3>
    <button
      type="button"
      class="close trans"
      aria-label="Close"
      (click)="onClose()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>

    <div class="mt-25 confirmation-button">
      <div class="abc-form-button">
        <button type="button" class="only-button" (click)="onClose()">
          Okay
        </button>
      </div>
    </div>
  </div>
</div>
