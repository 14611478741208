import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { LocalStorageService } from './local-storage.service';
import { User } from '../shared/user';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {
  private user: User | null = this.localStorageService.get('_user');
  private userIdLabel = 'user_id';
  private roleLabel = 'role';
  private buttonNameLabel = 'button_name';
  private pageEnterTime: number | null = null;
  private pageName: string | null = null;

  constructor(
    public analytics: AngularFireAnalytics,
    public localStorageService: LocalStorageService
  ) { }

  logPageView(pageName: string) {
    this.analytics.logEvent('page_view', { 
      page_name: pageName, 
      [this.userIdLabel]: this.user?._id || null, 
      [this.roleLabel]: this.user?.role || null 
    });
  }

  logButtonClick(buttonName: string, eventParams: { [key: string]: any }) {
    if(!eventParams[this.userIdLabel]){
      eventParams[this.userIdLabel] = this.user?._id || null;
      eventParams[this.roleLabel] = this.user?.role || null;
    }
    eventParams[this.buttonNameLabel] = buttonName;
    this.analytics.logEvent('button_click', eventParams);
  }

  logVideoView(videoId: string, duration: number) {
    this.analytics.logEvent('video_view', { 
      video_id: videoId, 
      duration: duration, 
      [this.userIdLabel]: this.user?._id || null, 
      [this.roleLabel]: this.user?.role || null 
    });
  }

  logCustomEvent(eventName: string, eventParams: { [key: string]: any }) {
    if(!eventParams[this.userIdLabel]){
      eventParams[this.userIdLabel] = this.user?._id || null;
      eventParams[this.roleLabel] = this.user?.role || null;
    }
    this.analytics.logEvent(eventName, eventParams);
  }

  logUserProperty(propertyName: string, propertyValue: string) {
    this.analytics.setUserProperties({ [propertyName]: propertyValue });
  }

  logPageEnter(pageName: string) {
    this.pageEnterTime = new Date().getTime();
    this.pageName = pageName;
    this.analytics.logEvent('page_enter', { 
      page_name: pageName, 
      [this.userIdLabel]: this.user?._id || null, 
      [this.roleLabel]: this.user?.role || null 
    });
  }

  logPageExit() {
    if (this.pageEnterTime && this.pageName) {
      const timeSpent = new Date().getTime() - this.pageEnterTime;
      const timeSpentInSeconds = (timeSpent / 1000).toFixed(2);
      console.log(`time spend: ${timeSpentInSeconds} on ${this.pageName}`)
      this.analytics.logEvent('page_exit', { 
        page_name: this.pageName, 
        time_spent: timeSpentInSeconds, 
        [this.userIdLabel]: this.user?._id || null, 
        [this.roleLabel]: this.user?.role || null 
      });
      this.pageEnterTime = null;
      this.pageName = null;
    }
  }
  
}
