import { WindowRefService } from '@app/pages/shared/services/windowref.service';
import { Component, PLATFORM_ID, OnInit, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Inject } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { FirebaseAnalyticsService } from './core/services/firebase-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'abctalkies';
  message: any = null;

  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private winRef: WindowRefService,
    public abcSpinner: SpinnerService,
    private analyticsService: FirebaseAnalyticsService
  ) {
    this.analyticsService.logCustomEvent('app_open', {"component": "AppComponent"});
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          (window as any).ga('set', 'page', event.urlAfterRedirects);
          (window as any).ga('send', 'pageview');
        }
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.addFirebase();
    }
  }

  private addFirebase() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js').then(
        () => { },
        (error) => {
          console.log('Service worker registration failed:', error);
        }
      );
    } else {
      console.log('Service workers are not supported.');
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.winRef.isMobile();
    }
     this.analyticsService.logCustomEvent('session_start', { time: new Date().toISOString() });
  }

  ngOnDestroy(): void {
    this.analyticsService.logCustomEvent('session_end', { time: new Date().toISOString() });
  }
}
