import { Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

export interface ModalOptions {
  class: string;
  backdrop?: string;
  keyboard?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalService: BsModalService) {}

  show(template: TemplateRef<any>, options: any): BsModalRef {
    return this.modalService.show(template, options);
  }
}
