import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HostListener, Inject, OnDestroy } from '@angular/core';
import { Component, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { SpinnerService } from '@app/core/services/spinner.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  ispopupshow: string;
  timeInterval: any;
  windowScrolled: boolean;

  constructor(
    private router: Router,
    public localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (
      window.pageYOffset > 500 ||
      document.documentElement.scrollTop > 500 ||
      document.body.scrollTop > 100
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  scrollToTop(): void {
    const currentScroll =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  ngOnInit(): void {
    this.getCookies();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
        this.footerAdj();
        document.body.classList.remove('abc-menu-open');
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.footerAdj();
      window.addEventListener('resize', () => {
        this.footerAdj();
      });
    }
  }

  closePopup(): void {
    this.localStorageService.set('ispopupshow', 'No');
    this.getCookies();
  }

  getCookies(): void {
    this.ispopupshow = this.localStorageService.get('ispopupshow');
  }

  footerAdj(): void {
    this.timeInterval = setTimeout(() => {
      const footer: any = document.querySelector('.abc-footer');
      const footerH = footer ? footer.clientHeight : 0;
      const abcLoginWrapper: any = document.querySelector('.abc-login-wapper ');
      const abcFixPageLayout: any = document.querySelector(
        '.abc-fix-page-layout '
      );
      footer.style.marginTop = `-${footerH}px`;
      if (abcLoginWrapper) {
        abcLoginWrapper.style.minHeight = `calc(100vh - ${footerH}px`;
      }
      if (abcFixPageLayout) {
        abcFixPageLayout.style.minHeight = `calc(100vh - ${footerH}px`;
      }
      const layoutWrapper: any = document.querySelector('.layout-wrapper');
      if (layoutWrapper) {
        layoutWrapper.style.paddingBottom = `${footerH}px`;
      }
    }, 300);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeInterval);
  }
}
