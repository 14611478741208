import { NotificationModel } from './../models/notification.model';
import { ApiResponseModel } from './../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  baseUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getNotificationList(): Observable<ApiResponseModel<NotificationModel[]>> {
    return this.http.get<ApiResponseModel<NotificationModel[]>>(
      `${this.baseUrl}/user/notification/list`
    );
  }

  getNotificationAfterSignup(
    token: any
  ): Observable<ApiResponseModel<NotificationModel>> {
    return this.http.get<ApiResponseModel<NotificationModel>>(
      `${this.baseUrl}/user/signup/notification?` + token
    );
  }

  readNotification(
    id: string,
    token: string
  ): Observable<ApiResponseModel<NotificationModel>> {
    return this.http.get<ApiResponseModel<NotificationModel>>(
      `${this.baseUrl}/user/notification/seen/` + id + '?' + token
    );
  }

  markAllasRead(
    token: string
  ): Observable<ApiResponseModel<NotificationModel>> {
    return this.http.get<ApiResponseModel<NotificationModel>>(
      `${this.baseUrl}/user/seen/all/notification?` + token
    );
  }
}
