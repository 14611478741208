import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { SpinnerService } from '../services/spinner.service';
import { Observable } from 'rxjs';
import { RoleList } from '../shared/role';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard  {
  constructor(private router: Router, private authService: AuthService, public abcSpinner:SpinnerService,
    @Inject(PLATFORM_ID) private platformId: object) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const routeRoles = next.data.roles as RoleList;
    const userRole = this.authService?.userValue?.role;
    const hasRole = routeRoles && userRole && routeRoles.includes(userRole);
    if (isPlatformBrowser(this.platformId)) {
      this.abcSpinner.isLoading.set(false)
      if (hasRole) {
        return true;
      }
      return false;
    }
      this.abcSpinner.isLoading.set(true);
      return false;
  }
}
