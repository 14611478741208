import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-qr-error-dialog',
  templateUrl: './qr-error-dialog.component.html',
  styleUrl: './qr-error-dialog.component.scss',
})
export class QrErrorDialogComponent {
  @Input() message: string | null = null;
  @Output() onclose = new EventEmitter<any>();

  onClose() {
    this.onclose.emit();
  }
}
