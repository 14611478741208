import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@app/core/core.module';
import { FooterComponent } from './components/footer/footer.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { ShareButtons } from 'ngx-sharebuttons/buttons';
import { ShareReferralComponent } from '@app/pages/profile/viewer-profile/share-referral/share-referral.component';
@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    LayoutComponent,
    ShareReferralComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    ShareButtons,
  ],
  providers: [provideShareButtonsOptions(shareIcons())],
  exports: [
    NavbarComponent,
    FooterComponent,
    LayoutComponent,
    ShareReferralComponent,
  ],
})
export class LayoutModule {}
