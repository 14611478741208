import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Path } from '@app/core/enums/path.enum';
import { environment } from '@environments/environment';

@Component({
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.scss'],
})
export class NotFoundPage {
  cdnUrl = environment.cdnUrl;

  constructor(private router: Router) { }

  routeToHome(): void {
    this.router.navigate([Path.Home]);
  }
}
