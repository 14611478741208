export enum Path {
  // Public
  Home = '',
  NotFound = '404',
  Static = 'static',
  PrivacyPolicy = 'privacy-policy',
  CopyrightPolicy = 'copyright-policy',
  EWalletTermsConditions = 'e-wallet-terms-conditions',
  UserTermsConditions = 'user-terms-conditions',
  FilmMakerTermsConditions = 'filmmaker-terms-conditions',
  ContactUs = 'contact-us',
  AboutUs = 'about-us',
  FILMMAKERDETAIL = 'filmmaker-detail',
  PUBLISHEDFILMLIST = 'published-movies',
  CookiesPolicy = 'cookies-policy',
  PaymentRefundPolicy = 'payment-refund-policy',
  MovieUploadSuccess = 'film-upload-success',
  IAMFILMMAKER = 'iamfilmmaker',
  SEARCH = 'search',
  BLANK = 'blank',
  FILMCONTEST = 'film-contest-terms-conditions',
  TELGUFILMCONTEST = 'big-shorts-challenge-terms-conditions',
  MALAYALAMFILMCONTEST = 'big-shorts-challenge-malayalam',
  TAMILFILMCONTEST = 'big-shorts-challenge',
  FILMSBYGENRE = 'filmsbygenre',
  FILMSBYLANGUAGE = 'filmsbylanguage',

  // Auth
  Auth = '',
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  SignInRole = 'sign-in-role',
  ForgotPassword = 'forgot-password',
  ForgotPasswordEmailSent = 'forgot-password-email-sent',
  PasswordReset = 'password-reset',
  PasswordResetSucceeded = 'password-reset-succeeded',
  PasswordResetFailed = 'password-reset-failed',
  EmailVerify = 'email-verify',
  ViewerSignUp = 'viewer/sign-up',
  FilmMakerSignUp = 'film-maker/sign-up',
  Filmmaker = 'filmmaker',
  ResetPassword = 'reset-password/:token',

  // App base url
  App = 'app',

  // Features
  Profile = 'profile',
  Dashboard = 'dashboard',
  CREATEFILM = 'create-film',
  FILMLIST = 'film-list',
  VIDEOPLAYER = 'video-player',
  MOVIEDETAIL = 'movie-detail',
  MostWatched = 'most-watched',
  EDITFILM = 'edit-film',
  AllFilm = 'View-film-list',
  PAYMENTSUCCESS = 'payment/success',
  WILDCARDROUTE = '**',
  //Profile
  VIEWER = 'viewer',
  SUCCESS = 'success',
  RETURN = 'return',

  QR_CODE = 'qr-code/:qrCodeId',
}
