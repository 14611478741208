import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BigShortService {

  constructor() { }

  isBigShortChallengeActive = signal(false);

}
