import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  returnUrl: string | null;
  constructor(private route: ActivatedRoute) {
    this.returnUrl = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void { }

}
