<header>
    <img src="assets/images/logo.png" class="img-fluid img-center" alt="">
  </header>

  <section class="abc-app-section pt-80 pb-80">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <p class="download-para">Download our app for the ultimate viewing experience.</p>
        </div>
      </div>
      <div class="row justify-content-center px-sm-3">
        <div class="col-sm-10 app-col">
          <img src="assets/images/abc-logo.svg" class="img-center mb-4 abc-logo" alt="">
          <div class="app-list-box">
            <div class="app-text">App available on</div>
            <div class="app-link-box">
              @if (returnUrl == 'ios') {
                <a href="https://apps.apple.com/us/app/abc-talkies/id1586757822" target="_blank">
                  <img src="assets/images/google-app.png" class="img-fluid" alt="">
                </a>
                <a href="https://apps.apple.com/us/app/abc-talkies/id1586757822" target="_blank">
                  <img src="assets/images/ios-app.png" class="img-fluid" alt="">
                </a>
              }
              @if (returnUrl == 'android') { 
                <a href="https://play.google.com/store/apps/details?id=abctalkies.boxoffice.android&pcampaignid=web_share" target="_blank">
                  <img src="assets/images/google-app.png" class="img-fluid" alt="">
                </a>
                <a href="https://play.google.com/store/apps/details?id=abctalkies.boxoffice.android&pcampaignid=web_share" target="_blank">
                  <img src="assets/images/ios-app.png" class="img-fluid" alt="">
                </a>
              }
            </div>
          </div> 
        </div>
      </div>
    </div>
  </section>

  <footer>
    <div class="footer-text">© 2024 ABC Talkies. All Rights Reserved.</div>
  </footer>